import React, { useEffect, useState } from "react";
import GuestFooter from "./GuestFooter";
import GuestHeader from "./GuestHeader";

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Galleria } from 'primereact/galleria';
import { loadEventGallery } from "./GuestApiServices";

import "primereact/resources/themes/lara-light-cyan/theme.css";
import { BASE_API_URL } from "../../common/Constants";

import GuestYoutubeVideoTile from "./GuestYoutubeVideoTile";



const GuestYoutubeContainer = () => {

    const [visible, setVisible] = useState(false);
    const [currentSelectedEvent, setCurrentSelectedEvent] = useState(undefined);
    const [images, setImages] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);  
    const [prevDisabled,setPrevButtonDisabled] =useState(false);
    const [nextDisabled,setNextButtonDisabled] =useState(false);

    useEffect(() => {
        if (currentSelectedEvent !== undefined && currentSelectedEvent !== null) {
            loadGalleryBasedOnEvent(currentSelectedEvent);
        }
    }, [currentSelectedEvent]);

    useEffect(()=>{
        if(activeIndex == 0){
            setPrevButtonDisabled(true);
            setNextButtonDisabled(false);
        }
        else if(activeIndex == images.length -1){
            setNextButtonDisabled(true);
        }
        else{
            setPrevButtonDisabled(false)
            setNextButtonDisabled(false)
        }
    },[activeIndex])

    useEffect(() => {
        if (visible == false) {
            setCurrentSelectedEvent(undefined);
        }
    }, [visible])

    const loadGalleryBasedOnEvent = (eventID) => {
        if (!eventID) return;

        let data = {
            eventId: eventID
        };

        loadEventGallery(data).then((response) => {
            console.log(response);
            if (response.data.success) {
                let data = response.data.data;
                if (data.length > 0) {
                    let images = data.map((e) => {
                        let x = {
                            itemImageSrc: `${BASE_API_URL}uploads/gallery/${e.photoUrl}`,
                            thumbnailImageSrc: `${BASE_API_URL}uploads/gallery/${e.photoUrl}`,
                            alt: 'Gallery Image',
                            title: 'Title'
                        }

                        return x;
                    })

                    console.log("images".images);

                    setImages(images);
                    setVisible(true);
                }
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    const itemTemplate = (item) => {

        //return <video width="320" height="240" controls> <source src="./assets/movie.mp4" type="video/mp4"></source> </video>
        return <img src={item.itemImageSrc} alt={item.alt} className="galleria-item-image" />;
    };

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />
    }

    const footerContent = (
        <div className="row">
            <div className="col-lg-12">
                <Button label="Previous" disabled={prevDisabled} style={{float:'left'}} icon="pi pi-chevron-circle-left" onClick={() => setActiveIndex(activeIndex - 1)} className="p-button-text text-black" />
                <Button label="Next" disabled={nextDisabled} style={{float:'right'}} icon="pi pi-chevron-circle-right" iconPos="right" onClick={() => setActiveIndex(activeIndex + 1)} autoFocus className="p-button-text text-black"/>
            </div>
        </div>
    );

    return (
        <>
            <GuestHeader />
            <section className="py-5">
                <div className="container px-5">
                    <h2 className="fw-bolder fs-5 mb-4">Youtube Video from church</h2>
                    <div className="row gx-5">
                        <GuestYoutubeVideoTile  />
                    </div>
                </div>

            </section>
            <GuestFooter />
        </>
    );
};

export default GuestYoutubeContainer;
