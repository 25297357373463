import React, { useEffect, useState } from "react";
import { loadYouTubeLinks } from "./GuestApiServices";
import { BASE_API_URL } from "../../common/Constants";

const GuestYoutubeVideoTile = (props) => {
    const [videoLinks, setVideoLinks] = useState([]);

    useEffect(() => {
        fetchYouTubeLinks();
    }, []);

    const fetchYouTubeLinks = () => {
        let filterData = {
            linkType:'youtube'
        }
    
        loadYouTubeLinks(filterData).then((response) => {
            if (response.data.success) {
                if (response.data.data.length > 0) {
                    setVideoLinks(response.data.data);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const renderVideoTiles = () => {
        return videoLinks.map((video, index) => (
            <div className="col-lg-4 mb-5" key={index}>
                <div className="card h-100 shadow border-0" style={{ cursor: "pointer" }}>
                    <iframe
                        className="card-img-top"
                        width="100%"
                        height="200"
                        src={`https://www.youtube.com/embed/${extractYouTubeVideoId(video.LinkUrl)}`}
                        title={video.LinkTitle}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <div className="card-body p-4">
                        <div className="badge bg-primary bg-gradient rounded-pill mb-2">
                            YouTube
                        </div>
                        <div className="h5 card-title mb-3">{video.LinkTitle}</div>
                        <p className="card-text mb-0">{video.LinkDesc}</p>
                    </div>
                    <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                        <div className="d-flex align-items-end justify-content-between">
                            <div className="d-flex align-items-center">
                                <img
                                    className="rounded-circle me-3"
                                    src="https://dummyimage.com/40x40/ced4da/6c757d"
                                    alt="..."
                                />
                                <div className="small">
                                    <div className="fw-bold">Uploaded By</div>
                                    <div className="text-muted">{video.uploader || "Content Manager"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    const extractYouTubeVideoId = (url) => {


    console.log(">>>>",url);

        const regex = /(?:https?:\/\/)?(?:www\.|m\.)?(?:youtube\.com\/.*v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    return (
        <>
            {videoLinks.length > 0 ? (
                <div className="row">
                    {renderVideoTiles()}
                </div>
            ) : (
                "No Videos Available"
            )}
        </>
    );
};

export default GuestYoutubeVideoTile;