import React, { useEffect, useState } from 'react';
import Announcement from './Announcement';
import Event from './Event';
import QurbanaSponsors from './QurbanaSponsors';
import ImportantLink from './ImportantLink';
import GuestHeader from '../GuestHeader';
import GuestFooter from '../GuestFooter';
import { getAnnoucements, getImportantLinks, getSponsorList, getUpComingEvents } from '../../Admin/sundayupdates/NoticeUpdateService';

const NoticeBoard = () => {


  const [sponsors, setSponsors] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [announcementList, setAnnoucementList] = useState([]);
  const [importantLinkList, setImportantLinkList] = useState([]);


  const qurbanaSponsors = [

    {
      name: "Arun Raj & Family",
      date: "24th December 2024",
      description: ""
    },
    {
      name: "Roby K John & Family",
      date: "24th December 2024",
      description: "Memory of Poulose Thamarapilly 2nd Death Anniversary"
    },
    {
      name: "Zeraha Eldho Birthday",
      date: "10 December 2024",
      description: ""
    }
  ];

  const events = [
    {
      title: "Christmas Qurbana",
      date: "24th December 2024",
      time: "4:00 PM - 10:00 PM",
      location: "St Johns Church, Nottingham",
      description: "Christmas Eve Holy Qurbana Service"
    },
    {
      title: "Christmas Carol - Round 1",
      date: "14th December 2024",
      time: "TBA",
      location: "Nottingham, Ilkiston, Chesterfield, Burton, Ashbourn",
      description: "Christmas Carol service outside Derby & Nottingham"
    },
    {
      title: "Christmas Carol - Round 2",
      date: "21st December 2024",
      time: "TBA",
      location: "Nottingham and Derby",
      description: "Christmas Carol service in Nottingham and Derby areas"
    }
  ];

  const announcements = [
    {
      title: "2025 Qurbana Sponsor Calendar",
      content: "Please fill out the Google form for the 2025 Qurbana Sponsor Calendar list preparation. All church members are requested to participate.",
      date: "December 2024"
    },
    {
      title: "Christmas Secret Santa",
      content: "Marthamariyam Vanitha Samajam is conducting a Christmas Secret Santa this year. Please check the church website for more details.",
      date: "December 2024"
    }
  ];


  useEffect(() => {
    loadSponsorList();
    loadEventList();
    loadAnnoucementList();
    loadImportantLink();


  }, [])


  const loadSponsorList = () => {
    let filterData = {
      id: ''
    }

    getSponsorList().then((response) => {

      if (response.data.success) {
        console.log(response.data.data);
        setSponsors(response.data.data)
      }

    }).catch((error) => {
      setSponsors([])
    })
  }


  const loadEventList = () => {
    let filterData = {
      id: ''
    }

    getUpComingEvents().then((response) => {
      if (response.data.success) {
        console.log(response.data.data);
        setEventList(response.data.data)
      }
    }).catch((error) => {
      setEventList([])
    })
  }

  const loadAnnoucementList = () => {
    let filterData = {
      id: '',
    }

    getAnnoucements().then((response) => {
      if (response.data.success) {
        console.log(response.data.data);
        setAnnoucementList(response.data.data)
      }
    }).catch((error) => {
      setAnnoucementList([])
    })
  }

  const loadImportantLink = () => {

    let filterData = {
      linkType:'normal'
    }

    getImportantLinks(filterData).then((response) => {
      if (response.data.success) {
        console.log(response.data.data);
        setImportantLinkList(response.data.data)
      }
    }).catch((error) => {
      setImportantLinkList([])
    })

  }




  return (
    <>
      <GuestHeader />
      <div className="container">

        <div className="row justify-content-center">
          <div className="col-12">
            <h1 className="text-center mb-4">
              Church Notice Board
              <div className="fs-5 fw-normal mt-2">December 2024</div>
            </h1>
          </div>
        </div>

        <div className="row">
          {/* Left Column */}
          <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
            <div className="mb-4">
              <h2 className="h4 mb-3">Sunday Updates</h2>
              <QurbanaSponsors sponsors={sponsors} />
            </div>

            <div>
              <h2 className="h4 mb-3">Important Links</h2>

              {importantLinkList.map((linkData, index) => (
                <ImportantLink
                  key={index}
                  {...linkData}
                />
              ))}


            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="mb-4">
              <h2 className="h4 mb-3">Upcoming Events</h2>
              {eventList.map((event, index) => (
                <Event
                  key={index}
                  {...event}
                />
              ))}
            </div>


          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <h2 className="h4 mb-3">Announcements</h2>
            {announcementList.map((announcement, index) => (
              <Announcement
                key={index}
                {...announcement}
              />
            ))}
          </div>
        </div>
      </div>
      <GuestFooter />
    </>

  );
};

export default NoticeBoard;