import React, { useState } from 'react';

const YoutubeLinkForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    title: '',
    url: '',
    desc: '',
    linkType:'youtube',
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) newErrors.title = 'Title is required';
    if (!formData.url.trim()) newErrors.url = 'URL is required';
    if (!isValidUrl(formData.url)) newErrors.url = 'Please enter a valid URL';
    return newErrors;
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      onSubmit(formData);
      setFormData({ title: '', url: '', desc: '' });
      setErrors({});
    } else {
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="card mb-4">
      <div className="card-body">
        <h3 className="card-title mb-4">Add New Youtube Link</h3>

        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input
            type="text"
            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter link title"
          />
          {errors.title && <div className="invalid-feedback">{errors.title}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="url" className="form-label">URL</label>
          <input
            type="url"
            className={`form-control ${errors.url ? 'is-invalid' : ''}`}
            id="url"
            name="url"
            value={formData.url}
            onChange={handleChange}
            placeholder="Enter URL (e.g., https://example.com)"
          />
          {errors.url && <div className="invalid-feedback">{errors.url}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            rows="3"
            placeholder="Enter link description"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Add Link
        </button>
      </div>
    </form>
  );
};

export default YoutubeLinkForm;