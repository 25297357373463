import React, { useEffect, useRef, useState } from 'react';
import QurbanaSponsorForm from './QurbanaSponsorForm';
import QurbanaSponsorTable from './QurbanaSponsorTable';
import { AddUpdateSponsors, getSponsorList } from './NoticeUpdateService';
import { Toast } from 'bootstrap';

const QurbanaSponsorManagement = () => {
  const toast = useRef(null);

  const [sponsors, setSponsors] = useState([]);



  useEffect(() => {

    loadSponsorList();

  }, []);


  const loadSponsorList = () => {

    let filterData = {
      id: ''
    }

    getSponsorList().then((response) => {

      if (response.data.success) {
        console.log(response.data.data);
        setSponsors(response.data.data)
      }

    }).catch((error) => {
      setSponsors([])
    })

  }


  const handleSubmit = (formData) => {

    const newSponsor = {
      ...formData,
      ...{ activeStatus: 1 }
    };
    const jsonData = JSON.stringify(newSponsor);
    console.log('Data to be sent to backend:', jsonData);

    AddUpdateSponsors(jsonData).then((response) => {

      if (response.data.success) {
        loadSponsorList();
      }

    }).catch((error) => {
      alert(error);
    })

  };

  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }


  const handleDelete = (delete_id) => {
    // In a real application, you would make an API call here
    //setSponsors(prev => prev.filter((_, i) => i !== index))

    const jsonData = JSON.stringify({ id: delete_id, activeStatus: 0, deleteStatus: 1 });
    //we are updating the status here 
    AddUpdateSponsors(jsonData).then((response) => {

      if (response.data.success) {
        loadSponsorList();
      }

    }).catch((error) => {
      alert(error);
    })

  };

  const handleOrderUpdate = (delete_id) => {

    const jsonData = JSON.stringify({ id: delete_id, activeStatus: 0, deleteStatus: 1 });
    //we are updating the status here 
    AddUpdateSponsors(jsonData).then((response) => {
      if (response.data.success) {
        loadSponsorList();
      }

    }).catch((error) => {
      alert(error);
    })

  };

  return (
    <div className="container py-4">
      <h2 className="text-center mb-4">Qurbana Sponsor Management</h2>
      {/*  <Toast ref={toast} /> */}
      <div className="row">
        <div className="col-12">
          <QurbanaSponsorForm onSubmit={handleSubmit} />
        </div>
        <div className="col-12">
          <QurbanaSponsorTable
            sponsors={sponsors}
            onUpdateOrder={handleOrderUpdate}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default QurbanaSponsorManagement;