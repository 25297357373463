import React, { useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import "./pageFlip.css";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import GuestHeader from "../GuestHeader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Pages = React.forwardRef((props, ref) => {
    return (
        <div className="demoPage" ref={ref}>
            <p>{props.children}</p>
            <p>Page number: {props.number}</p>
        </div>
    );
});

function FlipBook() {
    const [numPages, setNumPages] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 350, height: 500 });
    const [iframeUrl, setIframeUrl] = useState("https://api.morbaseliosjsocderby.org/showbook?mid=121&view=large");

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const calculateDimensions = () => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Set flipbook dimensions as a percentage of the viewport
        const width = Math.min(viewportWidth * 0.6, 700); // Max width: 600px
        const height = Math.min(viewportHeight * 0.8, 1200); // Max height: 800px

        setDimensions({ width, height });

        // Update iframe URL based on screen size
        const view = viewportWidth <= 768 ? "mobile" : "large";
        setIframeUrl(`https://api.morbaseliosjsocderby.org/showbook?mid=121&view=large`);
    };

    useEffect(() => {
        // Calculate dimensions on load
        calculateDimensions();

        // Add event listener for window resize
        window.addEventListener("resize", calculateDimensions);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", calculateDimensions);
        };
    }, []);

    return (
        <>
             <GuestHeader />
            <section className="container-fluid">

            <div className="row">
                    <div className="col-lg-12">
                        <iframe
                            src={iframeUrl}
                            style={{
                                width: '100%',
                                height: '85vh',
                                border: "none",
                            }}
                            title="Flipbook Iframe"
                        ></iframe>
                    </div>
                </div>

              {/*   <div className="row" style={{ backgroundColor: "green" }}>
                    <div className="col-lg-12  vh-100" style={{ backgroundColor: 'red' }}>
                        <HTMLFlipBook
                            width={dimensions.width}
                            height={dimensions.height}
                            showCover={true}
                        >
                            {[...Array(numPages).keys()].map((n) => (
                                <Pages number={`${n + 1}`}>
                                    <Document
                                        file={pdf}
                                        onLoadSuccess={onDocumentLoadSuccess}

                                    >
                                        <Page pageNumber={n + 1} renderAnnotationLayer={false} renderTextLayer={false} width={dimensions.width} className='border-3 border-black' />
                                    </Document>

                                </Pages>
                            ))}
                        </HTMLFlipBook>
                    </div>
                </div> */}

            </section>
        </>
    );
}

export default FlipBook;
