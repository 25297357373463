import React, { useState, useRef, useEffect } from "react";
import GuestFooter from "./GuestFooter";
import GuestHeader from "./GuestHeader";
import { Toast } from 'primereact/toast';
import { sendEmail } from "./GuestApiServices";

import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';

const GuestContact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const toast = useRef(null);

    const [copiedState, setCopiedState] = useState("Click to copy");

    useEffect(() => {
        if (copiedState != "Click to copy") {
            setTimeout(() => {
                setCopiedState("Click to copy")
            }, 2000);
        }
    }, [copiedState])

    const [addressInfo] = useState([

        {
            members_to_contact: [
                {
                    title: "The Vicar",
                    name: "Fr Peter Kuriakose",
                    mobile: "+44 7411932075"
                },
                {
                    title: "Secretary",
                    name: "Shibu George",
                    mobile: "+44 7861394187"
                },
                {
                    title: "Trustee",
                    name: "Aby Abraham K",
                    mobile: "+44 7552246936"
                },
            ],
            basic: {
                email: "morbaseliosjacobitechurchderby@gmail.com"
            }
        }




    ]);

    const renderAddressInfo = () => {
        return addressInfo.map((info, index) => (
            <div className="row p-2">
                <Tooltip target=".copy-phone" />
                {/* Split address by commas and map through */}
                {info.members_to_contact.map((line, idx) => (
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-12 p-1">
                                <p className="text-muted title_text">{line.title}</p>
                            </div>
                            <div className="col-lg-12 text-center">
                                <span className="h6">{line.name}</span>
                            </div>
                            <div className="col-lg-12 text-center">
                                <i className="pi pi-phone p-2"></i>
                                <a href={`tel:${line.mobile}`} style={{ textDecoration: 'none', color: 'black' }}>{line.mobile}</a>
                                <i className="pi pi-copy copy-phone p-2 text-muted"
                                    data-pr-tooltip={copiedState}
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"
                                    onClick={() => { 
                                        toast.current.show({ severity: 'success', summary: 'Copied', detail: 'Phone number copied' });
                                        navigator.clipboard.writeText(`${line.mobile}`)
                                     }}
                                ></i>


                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ));
    };

    const validateForm = () => {
        if (!name.trim()) {
            toast.current.show({ severity: 'error', summary: 'Validation Error', detail: 'Name is required' });
            return false;
        }
        if (!email.trim()) {
            toast.current.show({ severity: 'error', summary: 'Validation Error', detail: 'Email is required' });
            return false;
        }
        if (!message.trim()) {
            toast.current.show({ severity: 'error', summary: 'Validation Error', detail: 'Message is required' });
            return false;
        }
        return true;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            // Replace with your actual submit logic
            let data = {
                name: name,
                email: email,
                message: message
            }
            sendEmail(data).then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message sent successfully' });
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Message sent failed' });
            })
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <GuestHeader />

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 mr-auto">
                            <h2>Contact Us</h2>
                            <p className="mb-5">Get in touch with church</p>
                            {renderAddressInfo()}
                        </div>
                        <div className="col-md-6 card shadow p-5">
                            <form className="mb-5" onSubmit={handleSubmit} id="contactForm" name="contactForm">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label htmlFor="name" className="col-form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            id="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label htmlFor="email" className="col-form-label">Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label htmlFor="message" className="col-form-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            id="message"
                                            cols={30}
                                            rows={7}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary rounded-0 py-2 px-4">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-md-12">
                            <h4>Get Directions</h4>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-md-12">
                            <h2>Mor Baselios JSO Church </h2>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                            <div className="col-lg-12">
                                <h4>Derby</h4>
                            </div>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2406.369512484595!2d-1.543742323613188!3d52.90577520759702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879f702bf564747%3A0xeafb5d32b962409d!2sOur%20Lady%20of%20Lourdes%20Catholic%20C%2C%2036%20Uttoxeter%20Rd%2C%20Mickleover%2C%20Derby%20DE3%209GE!5e0!3m2!1sen!2suk!4v1727616295635!5m2!1sen!2suk"
                                width="100%"
                                height="350"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />

                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="col-lg-12">
                                <h4>Nottingham</h4>
                            </div>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2402.458566692767!2d-1.152849373608808!3d52.97615375235804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c19ffc58c9b1%3A0x87cf8c7df4c24e08!2sSt%20John's%20Church%2C%20Carrington!5e0!3m2!1sen!2suk!4v1727616516702!5m2!1sen!2suk"
                                width="100%"
                                height="350"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />


                        </div>
                    </div>
                </div>

            </div>



            <GuestFooter />
        </>
    );
}

export default GuestContact;
