
export const BUILD_VERSION = "Build_Version";
export const TWITTER_USER = "TWITTER_USER";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const BAVA_NEWS = "BAVA_NEWS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT"
export const START_LOADING = "START_LOADING"
export const STOP_LOADING = "STOP_LOADING"
export const SET_RUMBLE_LOCAL = "SET_RUMBLE_LOCAL"
export const SET_COLLECTION = "SET_ACADEMY_COLLECTION"
export const SET_ASSET_COLLECTION = "SET_ASSET_COLLECTION"
export const FROM_DASHBOARD = "FROM_DASHBOARD";
export const USER_DATA = "User_Data"
export const USER_TOKEN = "User_Token"
export const APP_TOKEN = "Application_Token"
export const ACDMY_COLLECTION = "academyCollectionList";
export const ASSET_COLLECTION = "assetCollectionList";
export const STATUS_CODES = {
    HTTP_400: 400,
    HTTP_401: 401,
    HTTP_403: 403,
    HTTP_404: 404,
    HTTP_409: 409,
    HTTP_422: 422,
    HTTP_500: 500,
    HTTP_501: 501,
}


/**
 * API and Render url defined
 */
export const RENDER_URL = {
    SECRET_SANTA: '/christmas',
    SECRET_SANTA_CHECK: '/checksecret',
    LEADER_BOARD: '/leaderboard',
    LOGIN_URL: '/',
    ADMIN_LOGIN_URL: '/admin',
    ADMIN_DASHBOARD_URL: '/admin/dashboard',
    ADMIN_MASS_URL: '/admin/mass-services',
    CONTACT_URL: '/contact',
    BLOG_URL: '/blog',
    YOUTUBE_URL: '/youtube',
    SUNDAY_UPDATES_URL: '/sundayupdates',
    SUNDAY_UPDATES_DETAILS: '/sundayupdatesdetails',
    CANDLE_URL: '/candle',
    LAUNCH_URL: '/launch',
    BAVA_BAD_NEW_URL: '/bavathirumeni',
    DIGITAL_ALBUM: '/digitalalbum',
    ABOUT_URL: '/about',
    ABOUT_SUNDAYSCHOOL: '/sundayschool',
    ABOUT_YOUTH: '/youth',
    ABOUT_VANITHA: '/vanitha',
    ABOUT_STUDENT: '/student',
    ABOUT_PRAYERGROUP: '/unit',
    ABOUT_BAVA_URL: '/styeldo',
    ABOUT_MANAGEMENT_URL: '/management',
    ABOUT_ALTER_URL: '/acolytes',
    ABOUT_CHOIR_URL: '/choir',
    ORG_URL: '/organisations',
    BAVA_URL: '/spritualheads',
    TEAM_URL: '/team',
    REGISTER_URL: '/join',
    HOME_URL: '/DashboardHome',
    HOMENEW_URL: '/quests',
    ATTRIBUTE_URL: '/attribute',
    RESETPASSWORD_URL: '/reset',
    CHANGE_PASSWORD: '/change',
    USER_URL: '/createuser',
    SHOP_URL: '/shop',
    WALLET: '/wallet',

};


export const BASE_API_URL = "https://api.morbaseliosjsocderby.org/";

export const SUPPORTED_COINS_CODE = {
    SOLANO: "sol"
}

export const SOLANA_API = {
    BASE: "https://public-api.solscan.io",
    TOKEN_LIST: "/account/tokens",
    META_DETAILS: ""
}

export const shortenWallet = (text, count, insertDots) => {

    return text.slice(0, 16) + (((text.length > count) && insertDots) ? "..." : "") + text.slice(text.length - 16, text.length);
}


export const API_URL = {
    POST_EVENTS: "/admin/getAllEvents",
    POST_EVENTS_GALLERY: "/admin/getAllGallery",
    SEND_EMAIL: "/admin/contact_us",
    SECRET_SANTA_ADD: '/admin/secretregister',
    SECRET_SANTA_FIND: '/admin/find_match',
    SUNDAY_UPDATES: "/admin/getSundayUpdates",
    SPONSOR_UPDATES: "/admin/addsponsor",
    SPONSORS_LIST: "/admin/sponsorlist",
    ADD_UPCOMING_EVENTT: "/admin/addupcomingevents",
    UPCOMING_EVENT_LIST: "/admin/upcomingeventslist",
    ADD_ANNOUCEMENTS: "/admin/addannoucements",
    GET_ANNOUCEMENTS: "/admin/annoucementlist",
    ADD_LINKS: "/admin/addlinks",
    ADD_YOUTUBE_LINKS: "/admin/addyoutubelinks",
    GET_LINKS: "/admin/getlinklist",
    LOGIN_DASHBOARD: "/user/login",
    REGISTER_GUEST: "/userportal/registerAsGuest",
    REGISTER: "/userportal/register",
    RESET_GUEST: "/userportal/forgotPassword",
    WALLET_ADD: "/userportal/wallet/connect",
    WALLET_GET: "/userportal/wallet/get",
    WALLET_REMOVE: "/userportal/wallet/remove",
    CHANGE_PASSWORD: "/userportal/resetPassword",
    AVATAR_LIST: "/dashboard/list",
    AVATAR_FETCH_EXPAND: "/dashboard/avatar/expand",
    ADD_AVATAR: "/dashboard/avatar/add",
    UPDATE_AVATAR: "/dashboard/avatar/update",
    ATTRIBUTE_USER_V2: "/userportal/V2/attributes",
    ETH_NFT_V2: "/userportal/V2/getnfts",
    PROMO_API: "/userportal/connectWithPromo",
    UPDATE_USER: "/dashboard/user/update",
    CONNECT_NTF: "/userportal/connectAvatar",
    CONNECT_ASSETS: "/userportal/connectAsset",
    REMOVE_AVATAR: "/userportal/removeAvatar",
    REMOVE_ASSETS: "/userportal/removeAsset",
}


export const PLAY_FAB_URL = {
    LOGIN_CUSTOM_ID: "https://titleId.playfabapi.com/Client/LoginWithCustomID",
    LEADER_BOARD_AROUND: "https://titleId.playfabapi.com/Client/GetLeaderboardAroundPlayer",
    LEADER_BOARD_TOPTEN: "https://titleid.playfabapi.com/Client/GetLeaderboard"
}

export const PLAY_FAV_CONSTANTS = {
    TITLE_ID: '8DBB9',
    CUSTOM_ID: 'dr_68'
}


/**
 * 
 * @param {*} email 
 * @returns 
 */
export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};


function getColors(num) {
    const initialColor = Math.floor(Math.random() * 360);
    const increment = 360 / num;
    const hsls = [];
    for (let i = 0; i < num; i++) {
        hsls.push(Math.round((initialColor + (i * increment)) % 360));
    }
    return hsls;
}
export function generateRandomColors(_length) {
    const hsls = getColors(_length);
    let shuffled_hsls = hsls
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    return shuffled_hsls;
}


export const SUPPORT_COLLECTIONS = [
    {
        "code": "DAA",
        "name": "Degen Apes"
    },
    {
        "code": "DTP",
        "name": "Trash Pandas"
    },
    {
        "code": "AUR",
        "name": "Aurory"
    },
    {
        "code": "HEL",
        "name": "Helios"
    },
    {
        "code": "SMB",
        "name": "SMB"
    },
    {
        "code": "OKBR",
        "name": "Okay Bears"
    },
    {
        "code": "DGOD",
        "name": "DeGods"
    },
    {
        "code": "CETS",
        "name": "Cets on Creck"
    },
    {
        "code": "GGSG",
        "name": "Galactic Geckos"
    },
    {
        "code": "DBR",
        "name": "Drop Bears"
    },
    {
        "code": "CLNX",
        "name": "CLONE X"
    },
    {
        "code": "MBRD",
        "name": "Moonbirds"
    },
    {
        "code": "BAYC",
        "name": "Bored Ape Yacht Club"
    },
    {
        "code": "CYKG",
        "name": "CyberKongz"
    },
    {
        "code": "JIRA",
        "name": "Godjira"
    },
    {
        "code": "CCAT",
        "name": "Cool Cats"
    },
    {
        "code": "SLGT",
        "name": "Solgat"
    },
    {
        "code": "GRIM",
        "name": "Grim"
    },
    {
        "code": "YOTS",
        "name": "y00ts"
    },
    {
        "code": "MBPK",
        "name": "MoonbirdPunks"
    },
    {
        "code": "DRX",
        "name": "Degen Royale #188"
    }
]
