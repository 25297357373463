import React, { useEffect, useState } from 'react';
import { use } from 'react';

const QurbanaSponsorTable = ({ sponsors, onDelete, onUpdateOrder }) => {
  const [sponsorList, setSponsorList] = useState([]);
  const [orderNotChanged,setOrderNotChanged] = useState(true);

  useEffect(()=>{
    setSponsorList(sponsors);
  },[sponsors])

  const moveUp = (index) => {
    if (index === 0) return; // Cannot move the first item up
    const newSponsorList = [...sponsorList];
    [newSponsorList[index - 1], newSponsorList[index]] = [newSponsorList[index], newSponsorList[index - 1]];
    setOrderNotChanged(false);
    setSponsorList(newSponsorList);
  };

  const moveDown = (index) => {
    if (index === sponsorList.length - 1) return; // Cannot move the last item down
    const newSponsorList = [...sponsorList];
    [newSponsorList[index + 1], newSponsorList[index]] = [newSponsorList[index], newSponsorList[index + 1]];
    setOrderNotChanged(true);
    setSponsorList(newSponsorList);
  };

  const saveOrderChanges = () => {
    // Assign new order to each sponsor
    const updatedSponsors = sponsorList.map((sponsor, index) => ({
      ...sponsor,
      Order: index + 1 // Order will be from 1, 2, 3, ...
    }));
    setOrderNotChanged(false);
    // Call the API to update the order
    onUpdateOrder(updatedSponsors);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title mb-4">Qurbana Sponsors List</h3>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Date</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sponsorList.map((sponsor, index) => (
                <tr key={sponsor.Id}>
                  <td>{index + 1}</td>
                  <td>{sponsor.SponsorName}</td>
                  <td>{new Date(sponsor.Date).toLocaleDateString()}</td>
                  <td>{sponsor.SponsorDesc || '-'}</td>
                  <td>
                    <button
                      onClick={() => moveUp(index)}
                      className="btn btn-primary btn-sm me-2"
                      disabled={index === 0}
                    >
                      ⬆️
                    </button>
                    <button
                      onClick={() => moveDown(index)}
                      className="btn btn-primary btn-sm me-2"
                      disabled={index === sponsorList.length - 1}
                    >
                      ⬇️
                    </button>
                    <button
                      onClick={() => onDelete(sponsor.Id)}
                      className="btn btn-danger btn-sm me-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button disabled={orderNotChanged} onClick={saveOrderChanges} className="btn btn-success mt-3">
          Save Order
        </button>
      </div>
    </div>
  );
};

export default QurbanaSponsorTable;
