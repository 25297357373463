import React, { useState } from 'react';
import { addSecretSantaDetails, mixSanta } from './SecretService';

const SecretSantaRegistration = (props) => {


    const [formData, setFormData] = useState({
        fullName: '',
        familyName: '',
        dob: '',
        familySize: '',
        childrenCount: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [disableButton, setDisableButton] = useState(false);


    const mix = () => {

        mixSanta().then((response) => {
            console.log(response);
            props.onMix(response.data);
        }).catch((error) => {
            console.log("error", error);
        })

    }

    const handleSubmit = async (e) => {
        setError('');
        setDisableButton(true);
        e.preventDefault();

        if (!/\b\w+\b \b\w+\b/.test(formData.fullName)) {
            setError('Full Name must contain at least two words.');
            setDisableButton(false);
            return
        }

        const today = new Date(); const birthDate = new Date(formData.dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) { age--; }

        if (age < 18) {
            setDisableButton(false);
            setError('You must be at least 18 years old to register.'); return;
        }
        try {
            const newEvent = { ...formData };
            const jsonData = JSON.stringify(newEvent);

            /* addSecretSantaDetails(jsonData).then((response) => {
                if (response) {

                    setDisableButton(false);
                    setFormData({
                        fullName: '',
                        familyName: '',
                        dob: '',
                        familySize: '',
                        childrenCount: ''
                    });
                    if (response.data.success) {
                        setMessage("🎉 Congratulations! 🎉 You've successfully signed up for Secret Santa! 🎅✨Check back here after December 20th to discover your secret friend! 🌟🤩 The holiday magic awaits! 🎁💌")
                    }
                    else{
                        setError(response.data.message);
                    }


                }
            }).catch((error) => {
                setDisableButton(false);
                console.log("error", error);
            }) */


            alert('registration closed');
            return;

        } catch (error) {
            setDisableButton(false);
            console.log("Error", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="card-title mb-4">Secret Santa Registration</h3>

                {message && (
                    <div className="alert alert-success" role="alert">
                        {message}
                    </div>
                )}

                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Full Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="dob" className="form-label">Date of Birth</label>
                        <input
                            type="date"
                            className="form-control"
                            id="dob"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="familyName" className="form-label">Family Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="familyName"
                            name="familyName"
                            value={formData.familyName}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="familySize" className="form-label">Family Size</label>
                        <input
                            type="number"
                            className="form-control"
                            id="familySize"
                            name="familySize"
                            value={formData.familySize}
                            onChange={handleChange}
                            min="1"
                            required
                        />
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 col-sm-12 col-md-12 text-center'>
                            <button type="submit" disabled={disableButton} className="btn btn-danger">
                                Register for Secret Santa
                            </button>
                            {/* <button type="button" className="btn btn-success" onClick={() => {
                                mix();
                            }}>
                                MIX
                            </button> */}
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default SecretSantaRegistration;