import React, { useState } from 'react';
import { addSecretSantaDetails, findMyFriendSanta } from './SecretService';

const SecretSantaMatch = () => {
    const [formData, setFormData] = useState({
        fullname: '',
        dob: ''
    });
    const [match, setMatch] = useState(null);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!/\b\w+\b \b\w+\b/.test(formData.fullname)) {
            setError('Full Name must contain at least two words.');
            return
        }

        try {

            formData.fullname = formData.fullname.trim();

            const newEvent = { ...formData };
            const jsonData = JSON.stringify(newEvent);
            findMyFriendSanta(jsonData).then((response) => {
                if (response) {
                    if(response.data.success){
                        setMatch(response.data.data)
                    }
                    else{
                        setError(response.data.message);
                    }
                }
            }).catch((error) => {
                console.log("error", error);
            })
        } catch (error) {
            console.log("Error", error);
        }

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className="card">
            <div className="card-body">
                

                {match == null && <>
                
                <h3 className="card-title mb-4">Find Your Secret Santa Match</h3>

                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Your Full Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="fullname"
                            value={formData.fullname}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="dob" className="form-label">Date of Birth</label>
                        <input
                            type="date"
                            className="form-control"
                            id="dob"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Find My Match
                    </button>
                </form>
                </>
                }

                {match && (
                    <div className="mt-4">
                        <div className="alert alert-success">
                            <h4 className="alert-heading">Your Secret Santa Match!</h4>
                            <p className="mb-0">You will be the Secret Santa for: <strong>{match.FullName}</strong></p>
                            <hr />
                            <p className="mb-0">Family Details</p>
                            <ul className="list-unstyled mb-0">
                                <li>Family Name: {match.FamilyName}</li>
                                <li>Family Size: {match.FamilySize}</li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SecretSantaMatch;