import React from 'react';

const YoutubeLinkTable = ({ links, onDelete }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title mb-4">Youtube Links List</h3>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Title</th>
                <th>URL</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {links.map((link, index) => (
                <tr key={index}>
                  <td>{link.LinkTitle}</td>
                  <td>
                    <a 
                      href={link.LinkUrl} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-underline"
                    >
                      {link.LinkUrl}
                    </a>
                  </td>
                  <td>{link.LinkDesc || '-'}</td>
                  <td>
                    <button
                      onClick={() => onDelete(link.Id)}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default YoutubeLinkTable;