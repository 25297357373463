import React from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // Import NavLink and useLocation from react-router-dom
import { RENDER_URL } from '../../common/Constants';

const GuestHeader = () => {
    const location = useLocation(); // Hook to get the current location

    // Function to check if a link is active
    const isActive = (path) => {
        return location.pathname === path ? 'nav_active' : 'nav_inactive'; // Return 'active' if the current path matches
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
            <div className="container-fluid">
                <NavLink className="navbar-brand" to="/">
                    <img src="./assets/images/logo_custom_new_trans.png" className="imgLogo" alt="Church Logo" />
                    <b className="church_name_lg">Mor Baselios Jacobite Syrian Orthodox Church Derby </b>
                    <b className="church_name_sm">Mor Baselios JSOC</b>
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink
                                className={`nav-link ${isActive(RENDER_URL.LOGIN_URL)}`} // Use isActive function
                                to="/"
                            >
                                HOME
                            </NavLink>
                        </li>
                        {/*  <li className="nav-item">
                            <NavLink
                                className={`nav-link ${isActive(RENDER_URL.ABOUT_URL)}`} // Use isActive function
                                to="/about"
                            >
                                ABOUT US
                            </NavLink>
                        </li> */}
                        <li className="nav-item dropdown">

                            <a style={{ color: 'black' }} className="nav-link dropdown-toggle" id="navbarDropdownBlog" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">ABOUT US</a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownBlog">
                                <li>
                                    <NavLink
                                        className={`nav-link ${isActive(RENDER_URL.ABOUT_URL)}`} // Use isActive function
                                        to={RENDER_URL.ABOUT_URL}
                                    >PARISH</NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={`nav-link ${isActive(RENDER_URL.ABOUT_BAVA_URL)}`} // Use isActive function
                                        to={RENDER_URL.ABOUT_BAVA_URL}
                                    >ST. YELDHO BAVA</NavLink>
                                </li>
                                <li> <NavLink
                                    className={`nav-link ${isActive(RENDER_URL.ABOUT_MANAGEMENT_URL)}`} // Use isActive function
                                    to={RENDER_URL.ABOUT_MANAGEMENT_URL}
                                >ADMINISTRATION </NavLink></li>
                                <li> <NavLink
                                    className={`nav-link ${isActive(RENDER_URL.ABOUT_ALTER_URL)}`} // Use isActive function
                                    to={RENDER_URL.ABOUT_ALTER_URL}
                                >ACOLYTES </NavLink></li>
                                <li> <NavLink
                                    className={`nav-link ${isActive(RENDER_URL.ABOUT_CHOIR_URL)}`} // Use isActive function
                                    to={RENDER_URL.ABOUT_CHOIR_URL}
                                >CHOIR </NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">

                            <a style={{ color: 'black' }} className="nav-link dropdown-toggle" id="navbarDropdownOrg" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">SPRITUAL ORGANISATIONS</a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownOrg">
                                <li>
                                    <NavLink
                                        className={`nav-link ${isActive(RENDER_URL.ABOUT_SUNDAYSCHOOL)}`} // Use isActive function
                                        to={RENDER_URL.ABOUT_SUNDAYSCHOOL}
                                    >SUNDAY SCHOOL</NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={`nav-link ${isActive(RENDER_URL.ABOUT_YOUTH)}`} // Use isActive function
                                        to={RENDER_URL.ABOUT_YOUTH}
                                    >YOUTH ASSOCIATION</NavLink>
                                </li>
                                <li> <NavLink
                                    className={`nav-link ${isActive(RENDER_URL.ABOUT_VANITHA)}`} // Use isActive function
                                    to={RENDER_URL.ABOUT_VANITHA}
                                >VANITHA SAMAJAM </NavLink></li>
                                {/* <li> <NavLink
                                    className={`nav-link ${isActive(RENDER_URL.ABOUT_STUDENT)}`} // Use isActive function
                                    to={RENDER_URL.ABOUT_STUDENT}
                                >STUDENT MOVEMENT</NavLink></li> */}
                                <li> <NavLink
                                    className={`nav-link ${isActive(RENDER_URL.ABOUT_PRAYERGROUP)}`} // Use isActive function
                                    to={RENDER_URL.ABOUT_PRAYERGROUP}
                                >PRAYER GROUPS </NavLink></li>
                            </ul>

                            {/*  <NavLink
                                className={`nav-link ${isActive(RENDER_URL.ORG_URL)}`} // Use isActive function
                                to={RENDER_URL.ORG_URL}
                            >
                                SPRITUAL ORGANISATIONS
                            </NavLink> */}
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={`nav-link ${isActive(RENDER_URL.CONTACT_URL)}`} // Use isActive function
                                to="/contact"
                            >
                                CONTACT
                            </NavLink>
                        </li>
                       {/*  <li className="nav-item">
                            <NavLink
                                className={`nav-link ${isActive(RENDER_URL.BLOG_URL)}`} // Use isActive function
                                to={RENDER_URL.BLOG_URL}
                            >
                                BLOG
                            </NavLink>
                        </li> */}
                        <li className="nav-item dropdown">

                            <a style={{ color: 'black' }} className="nav-link dropdown-toggle" id="navbarDropdownBlog" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">MEDIA</a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownBlog">
                                <li>
                                <NavLink
                                className={`nav-link ${isActive(RENDER_URL.BLOG_URL)}`} // Use isActive function
                                to={RENDER_URL.BLOG_URL}
                            >
                                BLOG
                            </NavLink>
                                </li>
                                <li>
                                <NavLink
                                className={`nav-link ${isActive(RENDER_URL.YOUTUBE_URL)}`} // Use isActive function
                                to={RENDER_URL.YOUTUBE_URL}
                            >
                                YOUTUBE
                            </NavLink>
                                </li>

                                <li>
                                <NavLink
                                className={`nav-link ${isActive(RENDER_URL.DIGITAL_ALBUM)}`} // Use isActive function
                                to={RENDER_URL.DIGITAL_ALBUM}
                            >
                                DIGITAL ALBUM
                            </NavLink>
                                </li>
                              
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={`nav-link ${isActive(RENDER_URL.SUNDAY_UPDATES_URL)}`} // Use isActive function
                                to={RENDER_URL.SUNDAY_UPDATES_URL}
                            >
                                UPDATES
                            </NavLink>
                        </li>
                        {/*  <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                id="navbarDropdownBlog"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                BLOG
                            </a>
                            <ul
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="navbarDropdownBlog"
                            >
                                <li>
                                    <NavLink
                                        className={`dropdown-item ${isActive('/gallery')}`} // Use isActive function
                                        to="/gallery"
                                    >
                                        GALLERY
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={`dropdown-item ${isActive('/post')}`} // Use isActive function
                                        to="/post"
                                    >
                                        POST
                                    </NavLink>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default GuestHeader;
