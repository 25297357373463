import React, { useEffect } from "react";
import { Routes, Route, Navigate, } from "react-router-dom";
import { BUILD_VERSION, RENDER_URL, LOGOUT } from '../common/Constants'
import { httpInterceptor, setBaseUrl, setApplicationToken } from "../Core/HttpService"
import { GlobalContext, GlobalDispatchContext } from "../Context/Context";

import GuestContainer from "../Modules/Guest/GuestContainer";
import { ManageLocalStorage } from "./LocalStorage";
import { ManageSessionStorage } from "./SessionStorage";
import GuestContact from "../Modules/Guest/GuestContact";
import GuestAboutUsContainer from "../Modules/Guest/GuestAboutUsContainer";
import GuestOrganisation from "../Modules/Guest/GuestOrganisation";
import AdminLoginContainer from "../Modules/Admin/login/AdminLoginContainer";
import DashboardContainer from "../Modules/Admin/dashboard/DashboardContainer";
import MassContainer from "../Modules/Admin/mass/MassContainer";
import BavaDetailsPage from "../Modules/Guest/BavaDetailsPage";
import GuestPostContainer from "../Modules/Guest/GuestPostContainer";
import GuestAboutBaseiliosBava from "../Modules/Guest/GuestAboutBaseiliosBava";
import GuestAdministration from "../Modules/Guest/GuestAdministration";
import GuestSundaySchool from "../Modules/Guest/GuestSundaySchool";
import GuestCandle from "../Modules/Guest/GuestCandle";
import GuestLaunch from "../Modules/Guest/GuestLaunch";
import Notification from "../Services/Notification/Notification";
import BavaPass from "../Modules/Guest/BavaPass";

import DashboardContainerNew from "../Modules/Admin/dashboard/DashboardContainerNew";
import NoticeBoard from '../Modules/Guest/NoticeBoard/NoticeBoard'
import SecretSantaContainer from "../Modules/Guest/SecretSanta/SecretSanta";
import Magazines from "../Modules/Guest/Magazine/Magazine";
import FlipBook from "../Modules/Guest/Magazine/FlipBook";
import GuestYoutubeVideoTile from "../Modules/Guest/GuestYoutubeVideoTile";
import GuestYoutubeContainer from "../Modules/Guest/GuestYoutubeContainer";

const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)

    const clearBrowser = (e) => {
        dispatch({
            type: LOGOUT,
        });

        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
    }

    setBaseUrl(process.env.REACT_APP_BASE_URL);
    setApplicationToken(process.env.REACT_APP_TOKEN);
    httpInterceptor();


    const loginData = context.login;
    const _buildNo = ManageLocalStorage.get(BUILD_VERSION);
    console.log("buildNo", _buildNo);
    if (_buildNo == null || _buildNo != process.env.REACT_APP_BUILD_VERSION) {
        console.log("ENV", process.env.REACT_APP_BUILD_VERSION)
        clearBrowser(null);
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    } else {
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    }



    return (
        <Routes {...xProps}>
            {/* admin */}
            <Route path={RENDER_URL.ADMIN_LOGIN_URL} exact element={loginData.isLoggedIn ? <Navigate to={RENDER_URL.ADMIN_DASHBOARD_URL} /> : <AdminLoginContainer />} />
            <Route path={RENDER_URL.ADMIN_DASHBOARD_URL} exact element={loginData.isLoggedIn ? <DashboardContainerNew /> : <DashboardContainerNew />} />
            <Route path={RENDER_URL.ADMIN_MASS_URL} exact element={loginData.isLoggedIn ? <MassContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />} />

            <Route path={RENDER_URL.LOGIN_URL} exact element={loginData.isLoggedIn ? <GuestContainer/> : <GuestContainer />} />
            <Route path={RENDER_URL.HOME_URL} exact element={loginData.isLoggedIn ? <></> : <Navigate to={RENDER_URL.LOGIN_URL} />} />
            <Route path={RENDER_URL.CONTACT_URL} exact element={<GuestContact />} />
            <Route path={RENDER_URL.ABOUT_URL} exact element={<GuestAboutUsContainer />} />
            <Route path={RENDER_URL.ABOUT_BAVA_URL} exact element={<GuestAboutBaseiliosBava />} />
            <Route path={RENDER_URL.ABOUT_MANAGEMENT_URL} exact element={<GuestAdministration />} />
            <Route path={RENDER_URL.ABOUT_ALTER_URL} exact element={<GuestAdministration />} />
            <Route path={RENDER_URL.ABOUT_CHOIR_URL} exact element={<GuestAdministration />} />
            <Route path={RENDER_URL.ABOUT_SUNDAYSCHOOL} exact element={<GuestOrganisation />} />
            <Route path={RENDER_URL.ABOUT_YOUTH} exact element={<GuestOrganisation />} />
            <Route path={RENDER_URL.ABOUT_PRAYERGROUP} exact element={<GuestOrganisation />} />
            <Route path={RENDER_URL.ABOUT_VANITHA} exact element={<GuestOrganisation />} />
            <Route path={RENDER_URL.ABOUT_STUDENT} exact element={<GuestOrganisation />} />
            
            <Route path={RENDER_URL.ORG_URL} exact element={<GuestOrganisation />} />
            <Route path={RENDER_URL.BAVA_URL} exact element={<BavaDetailsPage />} />
            <Route path={RENDER_URL.BLOG_URL} exact element={<GuestPostContainer />} />
            <Route path={RENDER_URL.YOUTUBE_URL} exact element={<GuestYoutubeContainer />} />
            <Route path={RENDER_URL.SUNDAY_UPDATES_URL} exact element={<NoticeBoard />} />
            
            <Route path={RENDER_URL.SECRET_SANTA} exact element={<SecretSantaContainer/>}/>
            <Route path={RENDER_URL.SECRET_SANTA_CHECK} exact element={<SecretSantaContainer/>}/>

            <Route path={RENDER_URL.CANDLE_URL} exact element={<GuestCandle />} />

            <Route path={RENDER_URL.LAUNCH_URL} exact element={<GuestLaunch />} />

            <Route path={RENDER_URL.BAVA_BAD_NEW_URL} exact element={<BavaPass />} />

            <Route path={RENDER_URL.DIGITAL_ALBUM} exact element={<FlipBook />} />


            <Route path="/path" exact element={<Notification />} />
        </Routes>
    );
};
export default RootRouteGuard